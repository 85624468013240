import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"
import FullFooter from "./FullFooter"

import MinimalFooter from "./MinimalFooter"

//footer types = ["full", "minimal"]
export default function Footer({ type = "minimal" }) {
  const year = new Date().getFullYear()

  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          footerJson {
            logo
          }
          privacyPages: allPrivacyPagesJson(
            filter: { hasThisPage: { eq: true } }
          ) {
            nodes {
              title
              name
            }
          }
          site {
            siteMetadata {
              fullPracticeName
            }
          }
          office: allDataJson(filter: { optionsItem: { eq: "office" } }) {
            nodes {
              cityName
              cityZip
              googlePlaceLink
              stateAbbr
              streetName
              streetNumber
            }
          }
          forms: allDataJson(filter: { optionsItem: { eq: "forms" } }) {
            nodes {
              allSeamlessForms {
                iframeSrc
                title
              }
            }
          }
        }
      `}
      render={(data) => {
        const copyright = `©${year} ${data.site.siteMetadata.fullPracticeName}`

        // pull out get started
        //const form = data.forms.nodes[0].allSeamlessForms.find(f => f.title.toLowerCase() === "get started footer")

        return (
          <footer id="footer" className="footer">
            {type === "minimal" ? (
              <MinimalFooter
                office={data.office.nodes[0]}
                pageLinks={data.privacyPages.nodes}
                logo={data.footerJson.logo}
                practiceName={data.site.siteMetadata.fullPracticeName}
                copyright={copyright}
              />
            ) : (
              <FullFooter
                office={data.office.nodes[0]}
                pageLinks={data.privacyPages.nodes}
                logo={data.footerJson.logo}
                copyright={copyright}
                // form={form.iframeSrc}
              />
            )}
          </footer>
        )
      }}
    />
  )
}
