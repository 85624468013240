export function sortArchive(archiveArray) {
  archiveArray.sort((a, b) => (a.order > b.order ? 1 : -1))
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getUnique(startingArray, targetProperty, valueToCompare) {
  let properties = []
  for (let i = 0; i < startingArray.length; i++) {
    properties.push(startingArray[i][targetProperty])
  }
  if (properties.includes(valueToCompare)) {
    return false
  }
  return true
}

function getOnlyElementsWithTargetProperty(array, targetProperty) {
  var elementsWithTargetProperty = []
  for (let i = 0; i < array.length; i++) {
    elementsWithTargetProperty.push(array[i][targetProperty])
  }
  return elementsWithTargetProperty
}
function getNonMatchingObjectsFromAllElements(
  elementsWithTargetProperty,
  allElements,
  targetProperty
) {
  var nonMatching = []
  for (let i = 0; i < allElements.length; i++) {
    if (
      elementsWithTargetProperty.includes(allElements[i][targetProperty]) ===
      false
    ) {
      nonMatching.push(allElements[i])
    }
  }
  return nonMatching
}

export function createHtml(html) {
  return { __html: html }
}

export function telephoneStrip(phone) {
  return phone.replace(/[^\d]/g, "")
}

export const toSentenceCase = (str) => {
  return str.replace(/\.\s+([a-z])[^\.]|^(\s*[a-z])[^\.]/g, (s) =>
    s.replace(/([a-z])/, (s) => s.toUpperCase())
  )
}
