import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'

import Logo from './Logo'
import MenuLink from './MenuLink'


const MobileMenu = ({ navData, sideNavRef, toggleSideNav }) => {

    const sidenavClasses = classnames({
        "sidenav": true,
    })

    return (
        <div className={sidenavClasses} ref={sideNavRef}>
                
                <div className="sidenav-menu">
                    <Link to="/" title="Home" className="nav-logo mobile-logo">
                        <Logo logo={navData.logo} />
                    </Link>

                    <div className="sidenav-close" onClick={toggleSideNav}>
                        <span>&#10005;</span>
                    </div>

                    <div className="sidenav-links">
                        {navData.menuLinks.map((link, i) => (
                            <React.Fragment key={i}>
                                <MenuLink
                                    link={link}
                                />
                            </React.Fragment>
                        ))}

                        <MenuLink
                            link={navData.specialButton}
                            isSpecialButton
                            className="standard-button"
                        />
                    </div>
                </div>

        </div>
    )
}

export default MobileMenu
