import React from 'react'
import IframeResizer from "iframe-resizer-react"
import classNames from 'classnames'

const SeamlessIframe = ({ src, className }) => {
    
    const wrapperClasses = classNames("seamless-wrapper", className)
    
    return (
        <div className={wrapperClasses}>
            <IframeResizer
                src={src}
                style={{ width: '1px', minWidth: '100%'}}
            />
        </div>
    )
}

export default SeamlessIframe
