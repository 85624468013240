import React, { useRef } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import MenuLink from "./MenuLink"
import MobileSideNav from "./MobileMenu"

import Logo from "./Logo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

const NavBar = ({ customButton = false }) => {
  const data = useStaticQuery(graphql`
    query navQuery {
      allDataJson(filter: { optionsItem: { eq: "nav" } }) {
        nodes {
          logo
          menuLinks {
            title
            href
            titleTag
            type
          }
          specialButton {
            title
            href
            titleTag
            type
          }
        }
      }
    }
  `)
  const navData = data.allDataJson.nodes[0]

  const sideNavRef = useRef(null)

  const toggleSideNav = () => {
    sideNavRef.current.classList.toggle("sidenav--active")
  }

  return (
    <nav id="mainNav" className="navbar">
      <FontAwesomeIcon
        icon={faBars}
        className="sidenav-bars"
        onClick={toggleSideNav}
        color="#545454"
      />

      <div className="nav-flex">
        <Link to="/" title="Home" className="desktop-logo">
          <Logo logo={navData.logo} />
        </Link>

        <div className="nav-links desktop-nav">
          {navData.menuLinks.map((link, i) => (
            <React.Fragment key={i}>
              <MenuLink link={link} />
            </React.Fragment>
          ))}
        </div>

        {!customButton ? (
          <div className="desktop-nav" style={{ width: "140px" }}></div>
        ) : (
          <MenuLink
            link={customButton}
            isSpecialButton
            className="standard-button desktop-nav"
          />
        )}
      </div>

      <MobileSideNav
        navData={navData}
        sideNavRef={sideNavRef}
        toggleSideNav={toggleSideNav}
      />
    </nav>
  )
}

export default NavBar
