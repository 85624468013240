import React from "react"
import { Link } from "gatsby"

const MinimalFooter = ({
  office,
  pageLinks,
  logo,
  practiceName,
  copyright
}) => {
  const address = `${office.streetNumber} ${office.streetName}, ${office.cityName}, ${office.stateAbbr}  ${office.cityZip}`
  return (
    <div className="minimal-footer">
      <div className="minimal-footer__grid">
        <p>
          {copyright}
          <sup>®</sup>
        </p>
        <p>
          <a
            href={office.googlePlaceLink}
            target="_blank"
            title="Get directions"
            aria-label="open link in new browser tab"
          >
            {address}
          </a>
        </p>

        {pageLinks.map((link, i) => (
          <p key={i}>
            <Link to={`/${link.title}`}>{link.name}</Link>
          </p>
        ))}
      </div>
    </div>
  )
}

export default MinimalFooter
