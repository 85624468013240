import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import { AnchorLink } from "gatsby-plugin-anchor-links";

const MenuLink = ({ link, isSpecialButton, className  }) => {

    const linkClasses = classnames(className,{
        "nav-link": true && !isSpecialButton,
        "nav-btn": isSpecialButton
    })

    if (link.type === "internal") {
        return (
            <Link to={link.href} title={link.titleTag} className={linkClasses}>
                {link.title}
            </Link>
        )
    }

    if (link.type === "external") {
        return (
            <a href={link.href} title={link.titleTag} target="_blank" rel="noopener noreferrer" className={linkClasses}>
              {link.title}
            </a>
        )
    }

    if (link.type === "anchor") {
        return (
            <AnchorLink to={link.href} title={link.titleTag} className={linkClasses}>
                <span>{link.title}</span>
            </AnchorLink>
        )
    }

    return <></>
}

export default MenuLink
