/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import "../css/main.scss"
import { config } from "@fortawesome/fontawesome-svg-core"

import { CloudinaryContext } from "cloudinary-react"

import NavBar from "./NavigationBar/NavBar"
import Footer from "./footer/Footer"

import useAOS from "../hooks/useAOS"
import SkipContent from "./SkipContent/SkipContent"

config.autoAddCss = false

function Layout(props) {
  useAOS()
  return (
    <div id="layout" className={props.className}>
      <SkipContent id="main-content" />
      <CloudinaryContext
        className="cld-context"
        responsiveUseBreakpoints="true"
        cloudName="nuvolum"
        quality="auto"
        secure={true}
        fetchFormat="auto"
      >
        <div>
          <NavBar
            isNavOverHero={props.isNavOverHero}
            currentPath={props.path}
            customButton={props.customNavButton ? props.customNavButton : false}
          />

          <main id="main-content" className={props.pageTitle}>
            {props.children}
          </main>

          <Footer type={props.footerType} />
        </div>
      </CloudinaryContext>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
