import React from "react"
import { Link } from "gatsby"
import ImageMeta from "../ImageMeta"
import Seamless from "../SeamlessIframe"

const FullFooter = ({ logo, office, pageLinks, copyright, form }) => {
  return (
    <div className="full-footer">
      <div className="gradient-bar"></div>

      <div className="container">
        <div className="full-footer__grid">
          <div className="full-footer__info">
            <ImageMeta
              publicId={logo}
              cloudName="nuvolum"
              width="auto"
              responsive
              responsiveUseBreakpoints="true"
              className="mark"
            />

            <div className="full-footer__group">
              <p>
                <a
                  href={office.googlePlaceLink}
                  target="_blank"
                  title="Directions"
                >
                  {`${office.streetNumber} ${office.streetName},`}
                  <br />
                  {`${office.cityName}, ${office.stateAbbr}  ${office.cityZip}`}
                </a>
              </p>
            </div>

            <div className="full-footer__group">
              {pageLinks.map((link, i) => (
                <p className="privacy-link" key={i}>
                  <Link to={`/${link.title}`}>{link.name}</Link>
                </p>
              ))}
            </div>

            <div className="full-footer__group">
              <p>
                {copyright}
                <sup>®</sup>
              </p>
            </div>
          </div>

          <div className="full-footer__form" id="form">
            <Seamless src="https://secureform.seamlessdocs.com/f/7e72b740c75c5e8d88ce864905135d14?embedded=true" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullFooter
