import { useEffect } from "react";
import AOS from "aos";
import "../../node_modules/aos/dist/aos.css";
var throttle = require("lodash/throttle");

export default function useAOS() {
    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', refreshAOS)
        return () => document.removeEventListener('scroll', refreshAOS)
    }, []);

    function refreshAOS() {
        throttle(function () {
            AOS.refresh();
        }, 300)
    }
}